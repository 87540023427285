import { Component, OnInit, OnDestroy, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import * as moment from 'moment';
import { AccountService } from 'src/app/services/account-service';
import { IFilter } from 'src/app/services/api.service';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { CityService } from 'src/app/services/city.service';
import { DistrictService } from 'src/app/services/district.service';
import { FieldService } from 'src/app/services/field.service';
import { LevelService } from 'src/app/services/level.service';
import { PostService } from 'src/app/services/post.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { IField, ILevel, IPost, ISearch } from '../../interfaces';
import { SearchService } from 'src/app/services/search.service';
import { LoginService } from 'src/app/services/login-service';

@Component({
  selector: 'app-search-post-modal',
  templateUrl: './search-post-modal.html',
  styleUrls: ['./search-post-modal.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class SearchPostModalComponent implements OnDestroy, OnInit {
  @Input() public filter: IFilter;
  @Input() public isAdminView: boolean;
  @Input() public field: string;
  @Input() public fieldDisplay: string;
  public loading = false;
  public minDate: string;
  public maxDate: any = (new Date()).getFullYear() + 1;
  public allFieldNames = [''];
  public allFieldsItems = [];

  public selectedSearch;

  public startDate = null;
  public startTime = null;

  constructor(
    private modalController: ModalController,
    public postService: PostService,
    public searchService: SearchService,
    public fieldService: FieldService,
    public districtService: DistrictService,
    public levelService: LevelService,
    public utilitiesService: UtilitiesService,
    private broadcastService: BroadcastService,
    public cityService: CityService,
    public loginService: LoginService,
  ) {
    const $this = this;
  }

  public ngOnInit() {
    this.initDateTime();
    if (!this.field || this.field === 'fields') {
      // if (this.filter.where.districts) {
      //   this.changeDistrict();
      // } else {
      //   this.currentFields = this.fieldService.allFields;
      // }
      this.allFieldsItems = JSON.parse(JSON.stringify(this.fieldService.allFields));
      this.allFieldsItems.unshift({
        id: 0,
        name: '--- Chưa có sân ---',
      });
    }
  }

  public ngOnDestroy() {
    //
  }
  ionViewWillEnter() {
  }

  public changeSelectedSearch() {
    this.searchService.setFilter(this.filter);
    this.initDateTime();
  }

  async dismiss() {
    await this.modalController.dismiss();
  }
  public clearTime($event) {
    $event.stopPropagation();
    $event.preventDefault();
    this.startTime = null;
  }

  public clearDate($event) {
    $event.stopPropagation();
    $event.preventDefault();
    this.startDate = null;
  }

  async save() {
    if (!this.isAdminView && (!this.filter.where.status || this.filter.where.status.length === 0)) {
      this.filter.where.status = ['approved', 'done'];
    }

    if (!this.field || this.field === 'fields') {
      this.filter.where.fields = [];
      if (this.filter.where.lookups?.fields) {
        this.filter.where.lookups.fields.forEach((fieldItem) => {
          this.filter.where.fields.push(fieldItem.id);
        });
      }
    }

    if (!this.field || this.field === 'districts') {
      this.filter.where.districts = [];
      if (this.filter.where.lookups?.districts) {
        this.filter.where.lookups.districts.forEach((districtItem) => {
          this.filter.where.districts.push(districtItem.id);
        });
      }
    }

    if (!this.field || this.field === 'levels') {
      this.filter.where.levels = [];
      if (this.filter.where.lookups?.levels) {
        this.filter.where.lookups.levels.forEach((levelItem) => {
          this.filter.where.levels.push(levelItem.id);
        });
      }
    }

    if (!this.field || this.field === 'date') {
      if (this.startDate) {
        // this.filter.where.date = this.startDate;
        // set short date 2024-09-19
        if (this.startDate.length > 15) { // 2024-05-15T00:00:00+07:00
          this.filter.where.date = this.startDate.split('T')[0];
        } else {
          this.filter.where.date = this.startDate; // not change
        }
      } else {
        delete this.filter.where.date;
      }
    }

    if (!this.field || this.field === 'hour') {
      if (this.startTime) {
        if (this.startTime.length > 15) {
          this.startTime = moment(this.startTime).format('HH');
        }
        const timeArr = this.startTime.split(':');
        this.filter.where.hour = timeArr[0];
        if (timeArr.length > 1) {
          this.filter.where.minute = timeArr[1];
        }
      } else {
        delete this.filter.where.hour;
        delete this.filter.where.minute;
      }
    }

    this.broadcastService.broadcast('changeSearch', {
      filter: this.filter,
    });
    await this.modalController.dismiss({
      filter: this.filter,
    });
  }

  public changeDistrict() {
    if (this.filter.where.districts) {
      this.utilitiesService.setFilterLookupsForKey(this.filter, 'districts');
    }
  }

  private initDateTime() {
    this.startDate = null;
    this.startTime = null;
    const currentDateMin = new Date();
    currentDateMin.setFullYear(2024);
    currentDateMin.setMonth(0);
    this.minDate = currentDateMin.toISOString();

    if (this.filter.where.date) {
      this.startDate = this.filter.where.date;
    }
    if (this.filter.where.hour) {
      const currentDate = new Date();
      currentDate.setHours(this.filter.where.hour);
      // if (this.filter.where.minute) {
      //   currentDate.setMinutes(this.filter.where.minute);
      // } else {
      //   currentDate.setMinutes(0);
      // }
      currentDate.setMinutes(0);
      this.startTime = currentDate.toUTCString();
    }
  }
}
