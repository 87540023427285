import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import {
  Router,
} from '@angular/router';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { ConfirmModalComponent } from 'src/app/shared/modal/confirm/confirm-modal';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { ApiService, IFilter } from 'src/app/services/api.service';
import { PostService } from 'src/app/services/post.service';
import { IComment, ICommentLookup, IPost, IPostLookup, IPostResult } from '../../interfaces';
import { Observable, fromEvent } from 'rxjs';
import { LoginComponent } from '../../modal/login/login.component';
import { AccountService } from 'src/app/services/account-service';
import { DistrictService } from 'src/app/services/district.service';
import { LevelService } from 'src/app/services/level.service';
import { FieldService } from 'src/app/services/field.service';
import { SearchPostModalComponent } from '../../modal/search-post/search-post-modal';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { UserService } from 'src/app/services/user.service';
import { CommentService } from 'src/app/services/comment.service';
import { LoginService } from 'src/app/services/login-service';
import { CityService } from 'src/app/services/city.service';
import { SearchService } from 'src/app/services/search.service';

@Component({
  selector: 'app-list-posts',
  templateUrl: './list-posts.component.html',
  styleUrls: ['./list-posts.component.scss'],
})
export class ListPostsComponent implements OnDestroy,OnInit {
  @ViewChild('scrollElement', { static: true }) scrollElement: ElementRef;
  @Input() postType: string;
  @Input() status: string[]; // ['pending']
  @Input() postIds: number[]; // view saved posts
  @Input() public isAdminView: boolean;

  @ViewChild('researchAIFilter') researchAIFilter;

  public comments: { // key: postId
    [key: number]: string;
  } = {};

  public postedComments: { // key: postId
    [key: number]: ICommentLookup[];
  } = {};

  public objectKeys = Object.keys;
  public posts: IPostLookup[] = [];
  public enableLoadMore = false;

  public limit = 10;
  public filter: IFilter = {
    where: {
    },
    limit: 10,
    skip: 0,
    sortField: 'created',
    sortOrder: 'DESC',
  };
  public loading = true;
  public loadingMore = false;

  public searchOrders = ['postType', 'name', 'status', 'fields', 'districts', 'address',
    'date', 'hour', 'levels', 'quantity', 'contact', 'color', 'price', 'userId', 'description'];

  public searchDataTypeDefinitions: {
    [key: string]: {
      filterType: string;
      fieldName: string;
    };
  } = {
    postType: {
      filterType: 'string',
      fieldName: 'Post Type',
    },
    status: {
      filterType: 'array',
      fieldName: 'Trạng thái',
    },
    name: {
      filterType: 'string',
      fieldName: 'Tên',
    },
    date: {
      filterType: 'string',
      fieldName: 'Ngày',
    },
    hour: {
      filterType: 'number',
      fieldName: 'Giờ',
    },
    fields: {
      filterType: 'lookups-array',
      fieldName: 'Sân',
    },
    districts: {
      filterType: 'lookups-array',
      fieldName: 'Quận(Huyện)',
    },
    /*
    address: {
      filterType: 'string',
      fieldName: 'Địa chỉ',
    },
    */
    levels: {
      filterType: 'lookups-array',
      fieldName: 'Trình Độ',
    },
    price: {
      filterType: 'string',
      fieldName: 'Chi Phí',
    },
    contact: {
      filterType: 'string',
      fieldName: 'Liên hệ',
    },
    color: {
      filterType: 'string',
      fieldName: 'Màu áo',
    },
    quantity: {
      filterType: 'string',
      fieldName: 'Số Người Cần Tìm',
    },
    description: {
      filterType: 'string',
      fieldName: 'Ghi chú',
    },
    user: {
      filterType: 'lookups',
      fieldName: 'Người Đăng',
    },
  };

  private storage: NativeStorage;
  private subscriptions = [];

  constructor(
    public actionSheetController: ActionSheetController,
    public userService: UserService,
    private router: Router,
    private modalController: ModalController,
    private broadcastService: BroadcastService,
    private postService: PostService,
    private accountService: AccountService,
    public loginService: LoginService,
    private districtService: DistrictService,
    private commentService: CommentService,
    private levelService: LevelService,
    private fieldService: FieldService,
    private apiService: ApiService,
    public utilitiesService: UtilitiesService,
    private cityService: CityService,
    public searchService: SearchService,
  ) {
    this.storage = new NativeStorage();
  }

  async ngOnInit() {
    // Wait for currentCityId in app.component.ts
    const interval = setInterval(async () => {
      if (!this.utilitiesService.isLoadingRequiredData && this.cityService.currentCityId
          && this.districtService.allDistricts?.length > 0) {
        clearInterval(interval);
        await this.initFilter();
        await this.getData();
      }
    }, 200);

    const subscription = this.broadcastService.subjectUniversal.subscribe(async (msg) => {
      if (msg.name === 'changeCurrentCity') {
        await this.initFilter();
        await this.getData();
      }
    });
    this.subscriptions.push(subscription);

    // subscription = this.broadcastService.subjectUniversal.subscribe((msg) => {
    //   if (msg.name === 'scroll') {
    //     const scrollTop = msg.message.scrollTop;
    //     if (this.enableLoadMore && !this.loadingMore) {
    //       const page = this.posts.length / this.limit;
    //       const highPixel = page * 3500;
    //       const lowPixel = page * 3000;
    //       if (scrollTop > lowPixel && scrollTop < highPixel) {
    //         console.log('=== loadMore :');
    //         this.loadMore();
    //       }
    //     }
    //   }
    // });
    // this.subscriptions.push(subscription);
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public removeFilter(fieldName: string, event?) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    delete this.filter.where[fieldName];
    delete this.filter.where.lookups[fieldName];
    if (fieldName === 'hour') {
      delete this.filter.where.hour;
      delete this.filter.where.minute;
    }
    this.getData({
      manually: true,
    });
  }

  public search(fieldDisplay?: string, key?: string) {
    this.modalController.create({
      component: SearchPostModalComponent,
      componentProps: {
        filter: JSON.parse(JSON.stringify(this.filter)),
        field: key,
        fieldDisplay,
        isAdminView: this.isAdminView,
      },
    }).then((modal) => {
      modal.present();
      modal.onDidDismiss().then((res) => {
        if (!res || !res.data || !res.data.filter) {
          return;
        } else {
          if (!res || res.data.action === 'close') {
            return;
          }
          if (res.data.action === 'reset') {
            if (key) {
              delete this.filter.where[key];
            } else {
              this.resetFilter();
              return;
            }
          }

          const oldKeys = Object.keys(this.filter.where);
          const keys = Object.keys(res.data.filter.where);
          keys.forEach((whereKey) => {
            this.filter.where[whereKey] = res.data.filter.where[whereKey];
          });
          oldKeys.forEach((whereKey) => {
            if (keys.indexOf(whereKey) === -1) {
              delete this.filter.where[whereKey];
            }
          });
          this.utilitiesService.setFilterLookups(this.filter);
          this.getData({
            manually: true,
          });
        }
      });
    });
  }

  public async createSearch(event: {
    postResult: IPostResult;
    action: string;
    searchText?: string;
  }) {
    if (event.action === 'reset') {
      this.resetFilter();
      return;
    }
    if (event.action === 'modal-search') {
      this.search();
      return;
    }
    await this.initFilter(true);
    const postResult = event.postResult;
    const postFilter = postResult.post;
    if (postFilter) {
      if (this.postType) {
        postFilter.postType = this.postType; // avoid AI return wrong.
      }
      // don't use AI search for these properties
      // delete postFilter.name; // why delete this property ?
      if (postFilter.postType !== 'player') {
        delete postFilter.quantity;
      }
      const hasTime = postResult.minute || postResult.hour || postResult.date || postResult.month || postResult.year;
      if (hasTime) {
        postFilter.date = new Date();
        if (postResult.date) {
          const year = postResult.year || new Date().getFullYear();
          const month = postResult.month || (new Date().getMonth() + 1);
          if (event.action === 'search') {
            this.filter.where.date = this.utilitiesService.getIonDate(year, month, postResult.date);
          }
          postFilter.date.setFullYear(year);
          postFilter.date.setMonth((postResult.month - 1) || (new Date().getMonth()));
          postFilter.date.setDate(postResult.date);
        }
        if (postResult.hour) {
          if (event.action === 'search') {
            this.filter.where.hour = postResult.hour;
          }
          postFilter.date.setHours(postResult.hour);
        }
        if (postResult.minute) {
          if (event.action === 'search') {
            this.filter.where.minute = postResult.minute;
          }
          postFilter.date.setMinutes(postResult.minute);
        } else {
          postFilter.date.setMinutes(0);
        }
      }
    }

    if (event.action === 'search') {
      if (!postResult || Object.keys(postResult).length === 0) {
        this.search();
      } else {
        const resKeys = Object.keys(postFilter);
        resKeys.forEach((key) => {
          if (postFilter[key]) {
            if (['date', 'hour', 'minute'].indexOf(key) === -1) {
              this.filter.where[key] = postFilter[key];
            }
          }
        });
        this.getData({
          manually: true,
        });
      }
    } else { // add a post
      if (this.loginService.loading) {
        console.log('===== ListPostsComponent : createSearch: loginService loading = true, return');
        return;
      }
      this.accountService.showlogin().subscribe(() => {
        const newPost: IPost = {
          status: 'approved',
          postType: this.postType,
          userId: this.loginService.currentUser?.id,
          date: new Date(),
          description: event.searchText,
        };
        if (postFilter) {
          Object.keys(postFilter).forEach((whereKey) => {
            newPost[whereKey] = postFilter[whereKey];
          });
        }

        this.postService.add(newPost).subscribe((post: any) => {
          if (post) {
            if (!post.lookups) {
              post.lookups = {};
            }
            post.lookups.user = this.loginService.currentUser;
            this.posts.unshift(post);
            this.setPostLookups();
            // this.getData();
          }
        });
      });
    }
  }

  public addComment(postId) {
    this.accountService.showlogin().subscribe(() => {
      const message = this.comments[postId];
      const savedComment: IComment = {
        message,
        date: new Date(),
        postId,
        userId: this.loginService.currentUser.id,
      };
      this.apiService.post({
        resource: 'comments',
        payload: savedComment,
      }).subscribe(() => {
        this.comments[postId] = '';

        // get comments by postId
        const postIds = [postId];
        const filterComment: IFilter = {
          where: {
            postId: postIds,
            status: ['approved', 'read'],
          }
        };
        this.commentService.getComments(filterComment).subscribe((commentsResult) => {
          this.postedComments[postId] = [];
          commentsResult.forEach((commentResult) => {
            this.postedComments[postId].push(commentResult);
          });
        });
      }, (errPost) => {
        console.error('errPost = ', errPost);
        alert('Tạo bình luận không thành công');
        return errPost;
      });
    });
  }

  public changePostType() {
    this.broadcastService.broadcast('changePostType', {
      postType: this.postType,
    });
    this.resetFilter();
  }

  public async resetFilter() {
    await this.initFilter(true);
    await this.getData();
  }

  public doRefresh(event) {
    const $this = this;
    $this.getData({
      setLookups: false,
      event,
    });
  }

  public loadMore(infiniteScrollEvent?: any) {
    // setTimeout(() => {
    this.filter.skip += this.filter.limit;
    this.getData({
      setLookups: false,
      event: infiniteScrollEvent,
      isLoadMore: true
  });
    // }, 500);
  }

  // event for call event.target.complete(); when scroll dow to refresh
  public async getData(options?: {
    setLookups?: boolean;
    event?;
    isLoadMore?: boolean;
    manually?: boolean;
  }) {
    if (!options || !options.isLoadMore) {
      this.filter.skip = 0;
      this.loading = true;
    } else {
      this.loadingMore = true;
    }
    if (!this.filter.where) {
      await this.initFilter();
    }
    if (this.postType) {
      this.filter.where.postType = this.postType;
    }
    this.postService.getPosts(this.filter, {
      currentUserId: this.loginService.currentUser?.id,
      searchService: this.searchService,
      manually: options?.manually,
    }).subscribe((postsResult) => {
      this.loading = false;
      this.loadingMore = false;
      if (this.researchAIFilter) {
        this.researchAIFilter.countFilterLength(this.filter);
      }

      // set posts
      if (!postsResult) {
        this.enableLoadMore = false;
        return;
      }
      if (postsResult.length === 10) {
        this.enableLoadMore = true;
      } else {
        this.enableLoadMore = false;
      }
      if (options && options.isLoadMore) {
        this.posts = this.posts.concat(postsResult);
      } else {
        this.posts = postsResult;
      }

      // has userId show first
      this.posts.sort((a, b) => {
        if (a.userId && !b.userId) {
          return -1;
        }
        if (b.userId && !a.userId) {
          return 1;
        }
        return 0;
      });

      if (options && options.setLookups) { // maybe don't need
        this.setPostLookups();
      }

      const postIds = [];
      this.posts.forEach((post) => {
        postIds.push(post.id);
        this.comments[post.id] = '';

        if (!post.lookups) {
          post.lookups = {};
        }
        post.lookups.today = this.utilitiesService.getTodayStr(post.date);
      });

      // get comment
      this.postedComments = {};
      const filterComment: IFilter = {
        where: {
          postId: postIds,
          status: ['approved', 'read'],
        }
      };
      this.commentService.getComments(filterComment).subscribe((commentsResult) => {
        commentsResult.forEach((commentResult) => {
          const postId = commentResult.postId;
          if (!this.postedComments[postId]) {
            this.postedComments[postId] = [];
          }
          this.postedComments[postId].push(commentResult);
        });
        if (options?.event) {
          options.event.target.complete();
        }
      });
    });
  }

  public async savePostToUser(post: IPost) {
    let savedPosts: any = this.loginService.currentUser.savedPostIds;
    if (savedPosts) {
      if (savedPosts.indexOf(',') > -1) {
        savedPosts = savedPosts.split(',');
      } else {
        savedPosts = [savedPosts];
      }
    } else {
      savedPosts = [];
    }
    if (savedPosts.indexOf(post.id) === -1) {
      savedPosts.push(post.id);
      await this.userService.update({
        id: this.loginService.currentUser.id,
        savedPostIds: savedPosts,
      }).toPromise();
      this.loginService.currentUser.savedPostIds = savedPosts.join(',');
      if (window.confirm('Lưu bài viết thành công. Click Ok để xem các bài viết đã lưu, Cancel để đóng thông báo')) {
        this.router.navigate(['/tabs/thong-bao/ca-nhan']);
      };
    }
  }

  public async removeSavedPostFromUser(post: IPost) {
    let savedPosts: any = this.loginService.currentUser.savedPostIds;
    if (savedPosts) {
      if (savedPosts.indexOf(',') > -1) {
        savedPosts = savedPosts.split(',');
      } else {
        savedPosts = [savedPosts];
      }
    } else {
      savedPosts = [];
    }
    savedPosts = savedPosts.filter((postItemId) => Number(postItemId) !== Number(post.id));
    await this.userService.update({
      id: this.loginService.currentUser.id,
      savedPostIds: savedPosts,
    }).toPromise();
    this.loginService.currentUser.savedPostIds = savedPosts.join(',');
    if (this.postIds?.length > 0) {
      this.posts = this.posts.filter((postItem) => postItem.id !== post.id);
    }
  }

  public edit(post: IPost) {
    this.postService.edit(post, this.isAdminView).subscribe((resPost: IPost) => {
      if (resPost) {
        this.getData();
      }
    });
  }

  public done(post: IPost) {
    if (!this.loginService.currentUser || (post.userId !== this.loginService.currentUser.id)) {
      return;
    }
    post.status = 'done';
    this.apiService.put({
      resource: 'posts/' + post.id,
      payload: {
        status: post.status,
      }
    }).subscribe((resPost: any) => {
    }, (errPost) => {
      alert('errPost = ' + errPost);
    });
  }

  public undone(post: IPost) {
    if (!this.loginService.currentUser || (post.userId !== this.loginService.currentUser.id)) {
      return;
    }
    post.status = 'approved';
    this.apiService.put({
      resource: 'posts/' + post.id,
      payload: {
        status: post.status,
      }
    }).subscribe((resPost: any) => {
    }, (errPost) => {
      alert('errPost = ' + errPost);
    });
  }

  public approved(post: IPost) {
    post.status = 'approved';
    this.apiService.put({
      resource: 'posts/' + post.id,
      payload: {
        status: post.status,
      }
    }).subscribe((resPost: any) => {
      // this.getData();
    }, (errPost) => {
      alert('errPost = ' + errPost);
    });
  }

  public deleteComment(comment: ICommentLookup) {
    this.modalController.create({
      component: ConfirmModalComponent,
      componentProps: {
      },
    }).then((modal) => {
      modal.present();
      modal.onDidDismiss().then((detail) => {
        if (detail && detail.data) {
          const confirmText = detail.data.confirmText;
          if (confirmText && (confirmText.toLowerCase() === 'yes' || confirmText.toLowerCase() === 'y')) {
            this.apiService.put({
              resource: 'comments/' + comment.id,
              payload: {
                status: 'archived',
              },
            }).subscribe((resPost: any) => {
              comment.status = 'archived';
              this.postedComments[comment.postId] =
                this.postedComments[comment.postId].filter(commentItem => commentItem.id !== comment.id);
            }, (errPost) => {
              console.error('errPost = ', errPost);
            });
          }
        }
      });
    });
  }

  public delete(post: IPost) {
    this.posts = this.posts.filter(el => el.id !== post.id);
    this.postService.delete(post.id).subscribe(() => {
    });
  }

  public open(savedKey) {
    this.router.navigate(['/tabs/tab3'], { queryParams: { key: savedKey } });
  }

  public viewGroup(post: IPost) {
    const url = 'https://www.facebook.com/groups/' + post.groupId;
    window.open(url, '_blank');
  }

  private setPostLookups() {
    this.posts.forEach((post) => {
      if (!post.lookups) {
        post.lookups = {};
      }

      post.lookups.today = this.utilitiesService.getTodayStr(post.date);

      // field
      if (post.fields && (!post.lookups.fields || post.lookups.fields.length === 0)) {
        post.lookups.fields = [];
        if (this.fieldService.allFields && this.fieldService.allFields.length > 0) {
          this.fieldService.allFields.forEach((field) => {
            if (post.fields.indexOf(field.id) > -1) {
              post.lookups.fields.push(field);
            }
          });
        }
      }

      if ((!post.districts || post.districts.length === 0) && post.lookups.fields && post.lookups.fields.length > 0) {
        post.districts = [];
        post.lookups.fields.forEach((fieldItem) => {
          post.districts.push(fieldItem.district);
        });
      }
      if (this.districtService.allDistricts && this.districtService.allDistricts.length > 0) {
        post.lookups.districts = [];
        this.districtService.allDistricts.forEach((districtItem) => {
          if (post.districts.indexOf(districtItem.id) > -1) {
            post.lookups.districts.push(districtItem);
          }
        });
      }

      // level
      if ((post.levels && post.levels.length > 0) && (!post.lookups.levels || post.lookups.levels.length === 0)) {
        post.lookups.levels = [];
        if (this.levelService.allLevels && this.levelService.allLevels.length > 0) {
          this.levelService.allLevels.forEach((levelItem) => {
            if (post.levels.indexOf(levelItem.id) > -1) {
              post.lookups.levels.push(levelItem);
            }
          });
        }
      }
    });
  }

  // set the first saved search to this.filter
  private async initFilter(ignoreSavedSearch?: boolean) {
    return new Promise((resolve, rejects) => {
      this.filter.where = {};
      // if (!this.isAdminView) {
      // this.filter.where.status = ['approved', 'done'];
      this.filter.where.status = ['approved'];
      // }
      this.filter.limit = this.limit;
      this.filter.where.cityId = this.cityService.currentCityId;
      this.filter.where.districts = [];
      this.districtService.allDistricts.forEach((districtItem) => {
        this.filter.where.districts.push(districtItem.id);
      });
      if (this.status) {
        this.filter.where.status = this.status;
      }
      if (this.postType) {
        this.filter.where.postType = this.postType;
      }
      if (!this.filter.where.lookups) {
        this.filter.where.lookups = {};
      }
      if (this.postIds && this.postIds.length > 0) {
        this.filter.where = {
          id: this.postIds,
        };
        resolve(null);
      } else if (ignoreSavedSearch || !this.loginService.currentUser || !this.postType) {
        return resolve(null);
      }
      this.searchService.getSearches({ // set the first saved search to this.filter
        where: {
          postType: this.postType,
          userId: this.loginService.currentUser.id,
          cityId: this.cityService.currentCityId,
        }
      }).subscribe(() => {
        if (this.searchService.allSearches.length > 0) {
          try {
            this.searchService.selectedSearch = this.searchService.allSearches[0];
            this.searchService.setFilter(this.filter);
          } catch (exSearch) {
            console.error('==== list-posts.component.ts: initFilter: exSearch :', exSearch);
          }
        }
        resolve(null);
      });
    });
  }
}
